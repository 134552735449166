/* eslint-disable no-unused-vars */
import React from 'react';
import SbEditable from 'storyblok-react';
import ThemeContext from '~contexts/ThemeContext';
import ContentTypes from '~components/contentTypes';
import config from '../../gatsby-config';

const sbConfigs = config.plugins.filter((item) => {
  return item.resolve === 'gatsby-source-storyblok';
});
const sbConfig = sbConfigs.length > 0 ? sbConfigs[0] : {};

const loadStoryblokBridge = (cb) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${sbConfig.options.accessToken}`;
  script.onload = cb;
  document.getElementsByTagName('head')[0].appendChild(script);
};

const getParam = (val) => {
  let result = '';
  let tmp = [];

  window.location.search
    .substr(1)
    .split('&')
    .forEach((item) => {
      tmp = item.split('=');
      if (tmp[0] === val) {
        result = decodeURIComponent(tmp[1]);
      }
    });

  return result;
};

class StoryblokEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = { story: null, globalLayout: null };
  }

  componentDidMount() {
    loadStoryblokBridge(() => {
      this.initStoryblokEvents();
    });
  }

  static contextType = ThemeContext;

  loadStory() {
    window.storyblok.get(
      {
        slug: window.storyblok.getParam('path'),
        version: 'draft',
        resolve_relations: sbConfig.options.resolveRelations || [],
      },
      (data) => {
        this.setState({ story: data.story });
        this.loadGlobalLayout();
      },
    );
  }

  loadGlobalLayout() {
    window.storyblok.get(
      {
        slug: `global/layout`,
        version: 'draft',
      },
      (data) => {
        this.setState({ globalLayout: data.story });
      },
    );
  }

  initStoryblokEvents() {
    this.loadStory();

    const sb = window.storyblok;

    sb.on(['change', 'published'], () => {
      this.loadStory();
    });

    sb.on('input', (payload) => {
      if (this.state.story && payload.story.id === this.state.story.id) {
        // eslint-disable-next-line no-param-reassign
        payload.story.content = sb.addComments(payload.story.content, payload.story.id);
        this.setState({ story: payload.story });
      }
      // Global layout. Checking specific block if updated, so it can be edited from anywhere.
      if (this.state.globalLayout && payload.story.id === this.state.globalLayout.id) {
        this.setState({ globalLayout: payload.story });
      }
    });

    sb.pingEditor(() => {
      if (sb.inEditor) {
        sb.enterEditmode();
      }
    });
  }

  render() {
    const { themeState, setThemeState } = this.context;

    if (this.state.story == null) {
      return <div />;
    }

    if (this.state.globalLayout !== null) {
      setThemeState(this.state.globalLayout.content);
    }

    const { content, published_at } = this.state.story;

    return (
      <SbEditable content={content}>
        <div>
          {React.createElement(ContentTypes(content.component), {
            key: content._uid,
            blok: content,
            published_at,
          })}
        </div>
      </SbEditable>
    );
  }
}

export default StoryblokEntry;
